<template>
  <div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="!disabled ? isAdd ? '新增盘点单' : '编辑盘点单' : '查看盘点单'" custom-class="custom-dialog-max-width" @closed="form = null" v-if="form">
      <el-form ref="form" :model="form" :rules="rules" label-width="78px" label-position="right" :disabled="disabled">
        <div class="h s">
          <el-form-item prop="warehouseId" label="仓库" style="margin-right: 10px;">
            <quick-select v-model="form.warehouseId" url="api/warehouse" filterable placeholder="仓库名称" style="width: 180px;" @change="handleWarehouseChange" />
          </el-form-item>
          <!-- <datagrid-picker reference="添加商品" reference-type="primary" url="api/goods/sku" :exclude-keys="excludeGoods" :query-define="goodsQueryDefine" :popper-width="800" :show-index="false" multiple submit @submit="handleGoodsAdd" v-if="form.warehouseId">
            <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
              <el-input :maxlength="10" v-model="goodsQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入商品名称进行搜索" style="width: 240px;">
                <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
              </el-input>
            </div>
            <el-table-column prop="name" label="商品名称" min-width="180" />
            <el-table-column label="规格" min-width="120">
              <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs || scope.row.goodsSpec)}}</template>
            </el-table-column>
            <el-table-column prop="brandName" label="品牌" width="120" />
            <el-table-column prop="seriesName" label="商品系列" />
            <el-table-column prop="categoryName" label="分类" show-overflow-tooltip />
            <el-table-column label="单价" width="100" align="right">
              <template slot-scope="scope">￥{{(scope.row.price / 100).toFixed(2)}}起</template>
            </el-table-column>
          </datagrid-picker>-->
          <el-form-item label-width="0px">
            <sku-selector v-if="form.warehouseId" request-url="api/shop/salesGoods" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
          </el-form-item>
          <div class="flex"></div>
          <el-form-item prop="stocktakingTime" label="盘点时间" style="margin-right: 10px;">
            <el-date-picker v-model="form.stocktakingTime" type="datetime" placeholder="选择日期时间" value-format="timestamp"></el-date-picker>
          </el-form-item>
        </div>
        <el-form-item prop="items" label="商品" style="margin-top: 10px;">
          <el-table :data="form.items" empty-text="还没有添加要采购的商品" class="table-outdent">
            <el-table-column prop="goodsName" label="商品名称" min-width="160" />
            <el-table-column prop="goodsBrandName" label="品牌" width="120" />
            <el-table-column prop="goodsSeriesName" label="系列" />
            <el-table-column label="规格" min-width="120">
              <template slot-scope="scope">{{$goodsSpecConvert(scope.row.goodsSpec)}}</template>
            </el-table-column>
            <el-table-column prop="currentCount" label="当前库存数量" width="120" />
            <el-table-column label="实际库存数量" width="110">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.realCount" :min="0" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" style="width: 100%;" />
              </template>
            </el-table-column>
            <el-table-column label="备注" width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.info" :maxlength="100" />
              </template>
            </el-table-column>
            <el-table-column width="40">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete" size="mini" @click="handleGoodsRemove(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item prop="info" label="备注">
          <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="3" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!disabled">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit">保存</el-button>
        <el-button :loading="loading" type="danger" @click="doChecked" v-if="form.id">复核</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogConfirm" :append-to-body="true" title="复核确认" :close-on-click-modal="false" @close="checkForm.checkInfo = null" width="600px">
      <el-form ref="auditForm" :model="checkForm" :rules="auditRules" label-position="right" label-width="60px">
        <el-form-item label="备注：" prop="checkInfo">
          <el-input type="textarea" v-model="checkForm.checkInfo" resize="none" :rows="7" :maxlength="200" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="checkLoading" type="success" @click="doAudit()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getById, add, edit, submit, checked } from "@/api/inventory";
import { getGoodsById } from "@/api/warehouse";
import { mapGetters } from "vuex";
import skuSelector from "@/views/assembly/skuSelect";
export default {
  components: { skuSelector },
  data() {
    return {
      isAdd: false,
      disabled: false,
      loading: false,
      checkLoading: false,
      dialog: false,
      dialogConfirm: false,
      checkForm: {
        checkInfo: null,
      },
      form: null,
      stocktakingTime: null,
      goodsQueryDefine: {
        name: "",
      },
      auditRules: {},
      rules: {
        warehouseId: [{ required: true, message: "请选择库房" }],
        items: [
          { required: true, message: "请添加商品" },
          { type: "array", min: 1, message: "请添加商品" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
  },
  methods: {
    convertForm() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.items = form.items.map((o) => {
        return {
          goodsId: o.goodsId,
          realCount: o.realCount,
          info: o.info,
        };
      });
      return form;
    },
    doSubmit() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    doAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          add(this.convertForm())
            .then((res) => {
              this.$notify({
                title: "添加盘点单成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    doEdit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          edit(this.convertForm())
            .then((res) => {
              this.$notify({
                title: "编辑盘点单成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    doChecked() {
      this.dialogConfirm = true;
    },
    doAudit() {
      this.checkLoading = true;
      let params = {
        id: this.form.id,
        checkInfo: this.checkForm.checkInfo,
      };
      checked(params)
        .then((res) => {
          this.$notify({
            title: "复核成功",
            type: "success",
            duration: 2500,
          });
          this.checkLoading = false;
          this.dialogConfirm = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.checkLoading = false;
        });
    },
    loadStockInfo(g) {
      if (g && g.goodsId && this.form.warehouseId) {
        getGoodsById(g.goodsId, this.form.warehouseId)
          .then((res) => {
            if (res && res.length) {
              g.currentCount = res[0].count;
              g.realCount = res[0].count;
            } else {
              g.currentCount = 0;
              g.realCount = 0;
            }
          })
          .catch((err) => {
            g.currentCount = 0;
            g.realCount = 0;
          });
      } else {
        g.currentCount = 0;
        g.realCount = 0;
      }
    },
    resetForm(id) {
      if (id) {
        this.isAdd = false;
        this.loading = true;
        getById(id)
          .then((res) => {
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        let now = new Date();
        this.isAdd = true;
        this.form = Object.assign({
          stocktakingTime: new Date().getTime(),
          warehouseId: null,
          warehouseErpCode: "",
          warehouseName: "",
          info: "",
          managerName: this.user.entName,
          items: [],
        });
      }
      this.dialog = true;
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        this.form.items = (this.form.items || []).concat(
          list.map((o) => {
            let _no = {
              goodsId: o.id,
              goodsSpec: o.specs, //规格
              goodsName: o.name, //商品名称
              goodsBrandName: o.brandName, //品牌
              goodsSeriesName: o.seriesName,
              currentCount: null,
              realCount: null,
              info: "",
            };
            this.loadStockInfo(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(inx) {
      this.form.items.splice(inx, 1);
    },
    handleWarehouseChange(id, oldId, v) {
      this.form.warehouseErpCode = v.code;
      this.form.warehouseName = v.name;
      if (this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          this.loadStockInfo(o);
        });
      }
    },
  },
};
</script>
