<template>
  <div class="app-container">
    <div class="head-container">
      <!-- 盘点明细查询 -->
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <quick-select url="api/warehouse" v-model="query.warehouseId" filterable collapse-tags placeholder="库房名称" class="filter-item" @change="toQuery" clearable />
      <!-- <el-input v-model="query.warehouseName" clearable placeholder="库房名称" class="filter-item" style="width: 150px" @keyup.enter.native="toQuery" /> -->
      <!-- <el-input v-model="query.warehouseErpCode" clearable placeholder="库房编码" class="filter-item" style="width: 150px" @keyup.enter.native="toQuery" /> -->
      <el-input v-model="query.goodsName" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.erpCode" :maxlength="20" clearable placeholder="输入商品编码搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />

      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="success" @click="toDownload" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="stocktakingTime" label="盘点日期" width="160" :formatter="r => {return new Date(r.stocktakingTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="goodsName" label="商品名称" min-width="110" show-overflow-tooltip />
      <el-table-column prop="erpCode" label="商品ERP编码" width="140" show-overflow-tooltip />
      <el-table-column prop="warehouseName" label="库房" />
      <el-table-column prop="warehouseErpCode" label="库房编码" width="150" show-overflow-tooltip />
      <el-table-column prop="goodsSpec" label="规格" min-width="130" :formatter="$goodsSpecConvert" show-overflow-tooltip />
      <el-table-column prop="goodsBrandName" label="品牌" min-width="100" />
      <el-table-column prop="goodsSeriesName" label="系列" min-width="80" />
      <el-table-column prop="currentCount" label="当前库存量" min-width="90" />
      <el-table-column prop="realCount" label="实际库存量" min-width="90" />

      <el-table-column width="100px" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import eForm from "../inventory/form";
export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        dateRange: null,
        goodsName: null,
        erpCode: null,
        formCode: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    makeParams() {
      let query = JSON.parse(JSON.stringify(this.query));
      return query;
    },
    beforeInit() {
      this.url = "api/stocktaking/detail";
      let query = this.makeParams();
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: "stocktakingTime,desc" }, query);

      return true;
    },
    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data.formId);
      this.$refs.form.disabled = true;
    },
    toDownload() {
      // let params = Object.assign({ sort: "createAt,desc" }, this.makeParams());
      this.downloadLoading = true;
      download("api/stocktaking/detail/download", this.params)
        .then((result) => {
          downloadFile(result, "盘点明细查询", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>